import React, { useState } from 'react'
import { Typography, Box, Divider, Button, Stack, ListItem, List, ListItemText, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import { ReactComponent as MealIcon } from "../../Icons/Hostel/MealIcon.svg"
import Meals from "../../Icons/Hostel/Meals.svg"
import { ReactComponent as LifeStyleIcon } from "../../Icons/Hostel/LifeStyleIcon.svg"
import LifeStyle from "../../Icons/Hostel/lifeStyle.svg"
import { ReactComponent as PublicFacilityIcon } from "../../Icons/Hostel/PublicFacilityIcon.svg"
import PublicFacility from "../../Icons/Hostel/PublicFacility.svg"
import { ReactComponent as FacilitiesIcon } from "../../Icons/Hostel/FacilitiesIcon.svg"
import Facilities from "../../Icons/Hostel/Facilities.svg"
import { ReactComponent as ChargeableServiceIcon } from "../../Icons/Hostel/ChargeableServiceIcon.svg"
import ChargeableService from "../../Icons/Hostel/ChargeableService.svg"
import { ReactComponent as CounsellingIcon } from "../../Icons/Hostel/CounsellingIcon.svg"
import Counselling from "../../Icons/Hostel/Counselling.svg"
import Views1 from "../../Icons/Hostel/Views1.svg"
import Views2 from "../../Icons/Hostel/Views2.svg"
import Views3 from "../../Icons/Hostel/Views3.svg"


export default function Views({ hostelData }) {


    return (
        <>
            <Box py={6} sx={{ background: "#EFFEFF" }}>
                <Box display="flex" justifyContent='center'>
                    <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">VIEWS</Typography>
                </Box>
                <Grid container rowGap={5} columnGap={6} mt={2} pt={5} pb={10} justifyContent="center" marginBottom='0px'>
                    {/* {images?.map((itm, index) => (
                    <Grid>
                        <Card sx={{ maxWidth: 363, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                    component="img"
                                    image={Meals}
                                    alt="green iguana"
                                />
                                <CardContent >
                                    <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                        <MealIcon />
                                        <Typography variant="h5" component="div" color="#27BAC4"> Meals</Typography>
                                    </Stack>
                                    <Stack mt={3} gap="2px" textAlign="center">
                                        <Typography variant="h8_2"> Mon - Fri </Typography>
                                        <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography>
                                        <Typography mt={2} variant="h8_2">Sat, Sun & Public Holiday </Typography>
                                        <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography>
                                    </Stack>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))} */}
                    {hostelData?.Views?.map((item, index) => (
                        <Grid md={4} lg={3}>
                            <Card sx={{ maxWidth: 363, height: '100%', borderRadius: "50px", textAlign: "start", position: "relative", boxShadow: "1px 120px 28px -7px rgba(0,0,0,0.08)" }}>
                                <Typography variant="h6_3" sx={{ position: "absolute", color: "#FFFFFF", zIndex: 1, bottom: 20, left: 30 }}>{item?.title}</Typography>
                                <CardActionArea>
                                    <CardMedia sx={{ borderRadius: "20px", height: "100%" }}
                                        component="img"
                                        image={process.env.REACT_APP_FILE_PATH + item?.img}
                                        alt="green iguana"
                                    />
                                </CardActionArea>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        height: "100%",
                                        width: "100%",
                                        background: 'linear-gradient(to top, rgba(0,0,0,.3), rgba(0,0,0,0))',
                                    }}
                                />
                            </Card>
                        </Grid>
                    ))}
                    {/* <Grid md={4} lg={3}>
                        <Card sx={{ maxWidth: 363, height: '100%', borderRadius: "50px", textAlign: "start", position: "relative", boxShadow: "1px 120px 28px -7px rgba(0,0,0,0.08)" }}>
                            <Typography variant="h6_3" sx={{ position: "absolute", color: "#FFFFFF", zIndex: 1, bottom: 20, left: 30 }}>Room</Typography>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "100%" }}
                                    component="img"
                                    image={Views2}
                                    alt="green iguana"
                                />
                            </CardActionArea>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    height: "100%",
                                    width: "100%",
                                    background: 'linear-gradient(to top, rgba(0,0,0,.1), rgba(0,0,0,0))',
                                }}
                            />
                        </Card>
                    </Grid>
                    <Grid md={4} lg={3}>
                        <Card sx={{ maxWidth: 363, height: '100%', borderRadius: "50px", textAlign: "start", position: "relative", boxShadow: "1px 120px 28px -7px rgba(0,0,0,0.08)" }}>
                            <Typography variant="h6_3" sx={{ position: "absolute", color: "#FFFFFF", zIndex: 1, bottom: 20, left: 30 }}>Room</Typography>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "100%" }}
                                    component="img"
                                    image={Views3}
                                    alt="green iguana"
                                />
                            </CardActionArea>
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    height: "100%",
                                    width: "100%",
                                    background: 'linear-gradient(to top, rgba(0,0,0,.2), rgba(0,0,0,0))',
                                }}
                            />
                        </Card>
                    </Grid> */}
                </Grid>
            </Box>
        </>
    )
}
