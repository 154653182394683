import React, { useState } from 'react'
import { Typography, Box, Divider, Button, Stack, ListItem, List, ListItemText, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import { ReactComponent as MealIcon } from "../../Icons/Hostel/MealIcon.svg"
import Meals from "../../Icons/Hostel/Meals.svg"
import { ReactComponent as LifeStyleIcon } from "../../Icons/Hostel/LifeStyleIcon.svg"
import LifeStyle from "../../Icons/Hostel/lifeStyle.svg"
import { ReactComponent as PublicFacilityIcon } from "../../Icons/Hostel/PublicFacilityIcon.svg"
import PublicFacility from "../../Icons/Hostel/PublicFacility.svg"
import { ReactComponent as FacilitiesIcon } from "../../Icons/Hostel/FacilitiesIcon.svg"
import Facilities from "../../Icons/Hostel/Facilities.svg"
import { ReactComponent as ChargeableServiceIcon } from "../../Icons/Hostel/ChargeableServiceIcon.svg"
import ChargeableService from "../../Icons/Hostel/ChargeableService.svg"
import { ReactComponent as CounsellingIcon } from "../../Icons/Hostel/CounsellingIcon.svg"
import Counselling from "../../Icons/Hostel/Counselling.svg"
import parse from "html-react-parser";



export default function Service_facilities({ hostelData }) {


    const images = [
        {
            src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
        {
            src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
        },
        {
            src: "https://images.pexels.com/photos/7968277/pexels-photo-7968277.jpeg?auto=compress&cs=tinysrgb&w=600",
        },

    ];


    return (
        <>
            <Box mt={6} mb={4} display="flex" justifyContent='center'>
                <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">SERVICES & FACILITIES</Typography>
            </Box>

            <Grid container rowGap={5} columnGap={6} mt={5} pt={5} pb={10} justifyContent="center" marginBottom='0px'>
                {/* {images?.map((itm, index) => (
                    <Grid>
                        <Card sx={{ maxWidth: 363, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                    component="img"
                                    image={Meals}
                                    alt="green iguana"
                                />
                                <CardContent >
                                    <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                        <MealIcon />
                                        <Typography variant="h5" component="div" color="#27BAC4"> Meals</Typography>
                                    </Stack>
                                    <Stack mt={3} gap="2px" textAlign="center">
                                        <Typography variant="h8_2"> Mon - Fri </Typography>
                                        <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography>
                                        <Typography mt={2} variant="h8_2">Sat, Sun & Public Holiday </Typography>
                                        <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography>
                                    </Stack>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))} */}

                {hostelData?.serviceFacility?.map((item, index) => (
                    <Grid md={4} lg={3}>
                        <Card sx={{ maxWidth: 363, height: '100%', p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                            <CardActionArea>
                                <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                    component="img"
                                    image={process.env.REACT_APP_FILE_PATH + item?.img}
                                    alt="green iguana"
                                />
                                <CardContent >
                                    <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                        {/* <MealIcon /> */}
                                        <Typography variant="h5" component="div" color="#27BAC4">{item?.title}</Typography>
                                    </Stack>
                                    <Stack mt={3} gap="2px" textAlign="center">
                                        <Typography variant="h8_2">{Boolean(item?.description) ? parse(item?.description) : ""} </Typography>
                                        {/* <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography>
                                        <Typography mt={2} variant="h8_2">Sat, Sun & Public Holiday </Typography>
                                        <Typography color="#737373" variant="h8_2"> Breakfast & Dinner </Typography> */}
                                    </Stack>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
                {/* <Grid md={4} lg={3}>
                    <Card sx={{ maxWidth: 363, height: '100%', p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image={LifeStyle}
                                alt="green iguana"
                            />
                            <CardContent >
                                <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                    <LifeStyleIcon />
                                    <Typography variant="h5" component="div" color="#27BAC4"> Lifestyle</Typography>
                                </Stack>
                                <Stack mt={3} gap="5px" textAlign="start" px={3}>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2"> Morning Wakeup call</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Daily room cleaning service</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Weekly Laundry service</Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid md={4} lg={3}>
                    <Card sx={{ maxWidth: 363, height: '100%', p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image={Facilities}
                                alt="green iguana"
                            />
                            <CardContent >
                                <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                    <FacilitiesIcon />
                                    <Typography variant="h5" component="div" color="#27BAC4">Facilities</Typography>
                                </Stack>
                                <Stack mt={3} gap="5px" textAlign="start" px={3}>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Free Wi-Fi</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Electricity bill, Water & utility bill included</Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid md={4} lg={3}>
                    <Card sx={{ maxWidth: 363, height: '100%', p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image={Counselling}
                                alt="green iguana"
                            />
                            <CardContent >
                                <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                    <CounsellingIcon />
                                    <Typography variant="h5" component="div" color="#27BAC4"> Counselling</Typography>
                                </Stack>
                                <Stack mt={3} gap="5px" textAlign="start" px={3}>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Life counselling </Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Academic pathway counseling</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Psychological counselling</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">7-9 PM Study session</Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid md={4} lg={3}>
                    <Card sx={{ maxWidth: 363, height: '100%', p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image={PublicFacility}
                                alt="green iguana"
                            />
                            <CardContent >
                                <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                    <PublicFacilityIcon />
                                    <Typography variant="h5" component="div" color="#27BAC4"> Public Facility</Typography>
                                </Stack>
                                <Stack mt={3} gap="5px" textAlign="start" px={3}>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Public Learning Area</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2"> Microwave</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2"> Water dispenser</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Clothes ironing tool</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2"> Public refridgerator</Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid md={4} lg={3}>
                    <Card sx={{ maxWidth: 363, height: '100%', p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start" }}>
                        <CardActionArea>
                            <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                component="img"
                                image={ChargeableService}
                                alt="green iguana"
                            />
                            <CardContent >
                                <Stack direction="row" gap="3px" justifyContent="center" alignItems="center">
                                    <ChargeableServiceIcon />
                                    <Typography variant="h5" component="div" color="#27BAC4"> Chargeable service</Typography>
                                </Stack>
                                <Stack mt={3} gap="5px" textAlign="start" px={3}>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Tuition arrangement</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Outdoor activity arrangement</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Singapore tour arrangement</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Guardian Service</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Vaccination Certification service</Typography>
                                    <Typography sx={{ listStyleType: 'disc', display: 'list-item' }} variant="h8_2">Long term visit pass application</Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid> */}
            </Grid>
        </>
    )
}
