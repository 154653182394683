import React, { useState } from 'react'
import { Typography, Box, Divider, Button, Stack, ListItem, List, ListItemText, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import { ReactComponent as MealIcon } from "../../Icons/Hostel/MealIcon.svg"
import Meals from "../../Icons/Hostel/Meals.svg"
import { ReactComponent as LifeStyleIcon } from "../../Icons/Hostel/LifeStyleIcon.svg"
import LifeStyle from "../../Icons/Hostel/lifeStyle.svg"
import { ReactComponent as PublicFacilityIcon } from "../../Icons/Hostel/PublicFacilityIcon.svg"
import PublicFacility from "../../Icons/Hostel/PublicFacility.svg"
import { ReactComponent as FacilitiesIcon } from "../../Icons/Hostel/FacilitiesIcon.svg"
import Facilities from "../../Icons/Hostel/Facilities.svg"
import { ReactComponent as ChargeableServiceIcon } from "../../Icons/Hostel/ChargeableServiceIcon.svg"
import ChargeableService from "../../Icons/Hostel/ChargeableService.svg"
import { ReactComponent as CounsellingIcon } from "../../Icons/Hostel/CounsellingIcon.svg"
import Counselling from "../../Icons/Hostel/Counselling.svg"
import Views1 from "../../Icons/Hostel/Views1.svg"
import Views2 from "../../Icons/Hostel/Views2.svg"
import { ReactComponent as DownArrowIcon } from "../../Icons/DownArrow.svg"


export default function Rules() {
    return (
        <>
            <Box my={6} display="flex" justifyContent='center'>
                <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">VINGAHALL RULES</Typography>
            </Box>
            <Box sx={{ backgroundColor: "#C8E2F3", p: { md: 7, xs: 3 }, pt: 11 }}>
                <Typography variant="h6_1" color="#FFFFFF" sx={{ backgroundColor: "#27BAC4", p: 1, px: 4, borderRadius: "40px 40px 0 0", whiteSpace: "nowrap" }}>Management terms ( BK & RG )</Typography>
                <Grid container justifyContent="center" mt={.5} p={4} rowGap={2} columnGap={3} marginBottom='0px' sx={{ backgroundColor: "#FFFFFF" }}>
                    <Grid md={9}>
                        <Box>
                            <Typography variant="h7_2" sx={{ display: "list-item", listStyleType: "decimal" }}> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in ullamcorper velit,
                                et ornare erat. Donec lectus enim, varius a suscipit sit amet,
                                finibus quis turpis. Aenean quis ullamcorper quam. Pellentesque hendrerit fermentum lectus</Typography>
                        </Box>
                    </Grid>
                    <Grid md={2} >
                        <Stack height="100%" alignItems="end" justifyContent="center">
                            <Box variant='contained' sx={{ height: '40px', backgroundColor: "#FFFFFF", border: ".5px solid #9E9E9E", borderRadius: "8px", color: "#9E9E9E", display: "flex", flexDirection: "row", alignItems: "center", p: "10px", columnGap: "10px" }}>Show all  <DownArrowIcon fill="#9E9E9E" /></Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
