import React, { useState, useEffect } from 'react'
import { Typography, Box, Divider, Button, Stack, CardActionArea, CardActions, Grid, Card, CardContent, CardMedia } from "@mui/material"
import { ReactComponent as LocationIcon } from "../../Icons/location.svg"
import { ReactComponent as FavouritesIcon } from "../../Icons/favouritesIcon.svg"
import { ReactComponent as ShareIcon } from "../../Icons/ShareIcon.svg"
import Pricing from '../../Components/Hostel/Pricing'
import Service_facilities from '../../Components/Hostel/Service_facilities'
import Views from '../../Components/Hostel/Views'
import Rules from '../../Components/Hostel/Rules'
import Access from '../../Components/Hostel/Access'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
import parse from "html-react-parser";

export default function HostelReview() {

    const { id } = useParams()

    const [hostelData, setHostelData] = useState([])

    const getHostelById = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}gethostelbyId/${id}`);
            console.log(response?.data?.data);
            setHostelData(response?.data?.data)
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        getHostelById()
    }, [])



    return (
        <>
            <Box width="100%">
                <Box py={3} px={{ md: 7, xs: 3 }} >
                    <Stack direction="row" columnGap="10px" alignItems="center">
                        <Typography variant="h7_2" color='#ACACAC' sx={{ cursor: "pointer" }}> Home</Typography>
                        <Typography>{'>'}</Typography>
                        <Typography variant="h7_2" color='#007ECA' sx={{ borderBottom: '1px solid #007ECA', cursor: "pointer" }}> Vinga Hall@RG</Typography>
                    </Stack>
                    <Stack mt={3} direction={{ sm: "row", xs: "column" }} rowGap={2} justifyContent="space-between" alignItems={{ sm: "center", xs: "start" }}>
                        <Stack textAlign="start" gap={1}>
                            <Typography variant="h2">{hostelData?.title}</Typography>
                            <Stack direction="row" alignItems="center" columnGap="10px">
                                <LocationIcon fill='#222222' />
                                <Typography variant="h7_2">99 Rangoon Rd, Singapore 218384</Typography>
                            </Stack>
                        </Stack>
                        <Stack py={2} direction={{ xs: "row" }} columnGap="20px" rowGap={2}>
                            <Button variant="contained" sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "10px", width: "100px", height: "40px", border: "0.5px solid #9E9E9E", color: "#9E9E9E" }} startIcon={<ShareIcon fill="#9E9E9E" />}>Share</Button>
                            <Button variant="contained" sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "10px", width: "100px", height: "40px", border: "0.5px solid #9E9E9E", color: "#9E9E9E" }} startIcon={<FavouritesIcon fill="#9E9E9E" />}>Like</Button>
                        </Stack>
                    </Stack>
                </Box>

                <Box py={3} px={{ md: 7, xs: 3 }}>
                    <Stack rowGap="20px">
                        <Typography variant="h6_1">About the Hostel</Typography>
                        <Typography variant="h7_2">{Boolean(hostelData?.description) ? parse(hostelData?.description) : ""}</Typography>
                    </Stack>
                    <Pricing hostelData={hostelData} />
                    <Service_facilities hostelData={hostelData} />
                </Box>
                <Views hostelData={hostelData} />
                <Rules hostelData={hostelData} />
                {/* <Access /> */}
            </Box>
        </>
    )
}
