import React, { useState } from 'react'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Stack } from "@mui/material"
import { ReactComponent as LocationIcon } from "../../Icons/location.svg"
import { ReactComponent as FavouritesIcon } from "../../Icons/favouritesIcon.svg"
import { ReactComponent as ShareIcon } from "../../Icons/ShareIcon.svg"


export default function Pricing({ hostelData }) {

    return (
        <>
            <Box mt={6} mb={4} display="flex" justifyContent='center'>
                <Typography variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px" width="fit-content">PRICING</Typography>
            </Box>
            <Stack rowGap={3}>
                {hostelData?.HostelTypes?.map((item, index) => (
                    <>
                        <Typography variant="h6_1" color='#222222'>{item?.Hostel_type}</Typography>
                        <TableContainer sx={{ borderRadius: "12px", mb: 5 }}>
                            <Table sx={{ minWidth: 650, background: "#FAFAFA", }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h9_1" color='#333333'>Room Type</Typography>
                                        </TableCell>
                                        <TableCell> <Typography variant="h9_1" color='#333333'>6 Month Contract</Typography></TableCell>
                                        <TableCell> <Typography variant="h9_1" color='#333333'>12 Month Contract</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {item?.room_type.map((itm, ind) => (
                                        <TableRow key={item.name} sx={{ borderTop: ".5px solid #737373" }} >
                                            <TableCell ><Typography variant="h7_3" color='#333333'>{itm?.room_type_name || "-"}</Typography></TableCell>
                                            <TableCell ><Typography variant="h7_1" color='#333333'>{itm?.six_month_cont || "_"}</Typography></TableCell>
                                            <TableCell ><Typography variant="h7_1" color='#333333'>{itm?.twelve_month_cont || "-"}</Typography></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ))}


            </Stack>
        </>
    )
}
